import React from "react";

import About from "../AboutMeow/About";

import Tokenomics from "../Tokenomics/Tokenomics";

import Farming from "../MeowWifCrown/MeowWifCrown";
import Roadmap from "../Roadmap/Roadmap";

import BuyGuide from "../BuyGuide/BuyGuide";
import Header2 from "../Header2/Header2";

// import Partners from "../Partners/Partners";

export default function Home_page() {
  return (
    <div>
               <Header2 />

      <About />

      {/* <BuyGuide></BuyGuide> */}

      {/* <Tokenomics /> */}

      {/* <Farming /> */}
     
      {/* <Roadmap />   */}
      {/* <Partners /> */}

      {/* <FAQ /> */}
    </div>
  );
}
