import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Header2.css";
import { BsTelegram, BsDiscord } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";

export default function Header() {
  return (
    <Navbar className="main_nav_bar">
      <Container className="d-flex flex-column flex-lg-row justify-content-between align-items-center text-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        
        {/* Brand Name - Centered on mobile, aligned left on larger screens */}
        <Navbar.Brand
          href="https://www.theorderroom.com/"
          target="_blank"
          className=" mb-3 mb-lg-0"
          style={{
            fontSize: "20px",
          }}
        >
          <div style={{justifyContent:"center"}}>
            <h2 className="text-dark fontff text-center mx-2" style={{paddingLeft:"25px"}}>The Order Room</h2>
          </div>
          
        </Navbar.Brand>

        {/* Social Icons - Centered below text on mobile, aligned right on larger screens */}
        <Nav className="d-flex gap-3 justify-content-center">
          <div className="d-flex gap-3 justify-content-center onlyheader" >


          <a
            href="https://x.com/theorderroom"
            target="_blank"
            className="text-decoration-none"
          >
            <FaXTwitter className="sical-logo0" style={{ fontSize: "1.8rem" }} />
          </a>
          <a
            href="https://t.me/theorderroom"
            target="_blank"
            className="text-decoration-none"
          >
            <BsTelegram className="sical-logo0" style={{ fontSize: "1.8rem" }} />
          </a>
          <a
            href="https://discord.gg/WQYQWScJaP"
            target="_blank"
            className="text-decoration-none"
          >
            <BsDiscord className="sical-logo0" style={{ fontSize: "1.8rem" }} />
          </a>

          </div>

          {/* <a
            href="#"
            target="_blank"
            className="text-decoration-none"
          >
            <BsDiscord className="sical-logo-new" style={{ fontSize: "1.8rem" }} />
          </a> */}
        </Nav>
      </Container>
    </Navbar>
  );
}




// New

// import React from "react";
// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import { BsTelegram, BsDiscord } from "react-icons/bs";
// import { FaXTwitter } from "react-icons/fa6";

// export default function Header() {
//   return (
//     <Navbar style={{ width: '100%' }}>
//       <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        
//         {/* Brand Name - Centered */}
//         <Navbar.Brand
//           href="https://www.theorderroom.com/"
//           target="_blank"
//           style={{
//             fontSize: "20px",
//             marginBottom: "10px",
//           }}
//         >
//           <h2 className="text-dark fontff">The Order Room</h2>
//         </Navbar.Brand>

//         {/* Social Icons - Centered below text */}
//         <Nav style={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
//           <a
//             href="https://x.com/theorderroom"
//             target="_blank"
//             style={{ textDecoration: 'none' }}
//           >
//             <FaXTwitter style={{ fontSize: "1.8rem" }} />
//           </a>
//           <a
//             href="https://t.me/theorderroom"
//             target="_blank"
//             style={{ textDecoration: 'none' }}
//           >
//             <BsTelegram style={{ fontSize: "1.8rem" }} />
//           </a>
//           <a
//             href="https://discord.gg/WQYQWScJaP"
//             target="_blank"
//             style={{ textDecoration: 'none' }}
//           >
//             <BsDiscord style={{ fontSize: "1.8rem" }} />
//           </a>
//         </Nav>
//       </Container>
//     </Navbar>
//   );
// }
