//  CODE

import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import Swal from "sweetalert2";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import * as web3 from "@solana/web3.js";
import "./Sol.css";

import pejimg from "../Assets/bub2-log.webp";
import chimpa from "../Assets/chimpa-image.png";

import Countdown from "react-countdown";

const Completionist = () => {
  return <></>;
};

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <div className="row timer_here">
        <div className="col-3">
          <div>
            <h1 className="numbers">{days}</h1>
            <p className="days site_font mb-0">DAYS</p>
          </div>
        </div>
        <div className="col-3">
          <div>
            <h1 className="numbers">{hours}</h1>
            <p className="days site_font mb-0">HOURS</p>
          </div>
        </div>
        <div className="col-3">
          <div>
            <h1 className="numbers">{minutes}</h1>
            <p className="days site_font mb-0">Minutes</p>
          </div>
        </div>
        <div className="col-3">
          <div>
            <h1 className="numbers">{seconds}</h1>
            <p className="days site_font mb-0">Seconds</p>
          </div>
        </div>
      </div>
    );
  }
};

export default function Solanabuy() {
  const [balance, setBalance] = useState(0);
  const [raisedAmount, setRaisedAmount] = useState(0);
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const [solAmount, setSolAmount] = useState("");
  const [tokenAmount, setTokenAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const targetAddress = "Dk8MGprC5rqbfw2V5SRd8YQ5YzPFJHfv4mmaNo47t2FV"; // OWNER ACCOUNT

  const sendSol = (event) => {
    event.preventDefault();
    if (!connection || !publicKey) {
      Swal.fire({
        icon: "error",
        title: "Connect Wallet",
        text: "Please connect your wallet first.",
      });
      return;
    }

    const amount = parseFloat(solAmount);
    if (amount < 0.001) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Minimum contribution is 0.001 SOL",
      });
      return;
    }

    if (amount > balance) {
      Swal.fire({
        icon: "error",
        title: "Insufficient Balance",
        text: "Your wallet does not have enough SOL.",
      });
      return;
    }

    setLoading(true);

    const transaction = new web3.Transaction();
    const recipientPubKey = new web3.PublicKey(targetAddress);

    const sendSolInstruction = web3.SystemProgram.transfer({
      fromPubkey: publicKey,
      toPubkey: recipientPubKey,
      lamports: Math.floor(LAMPORTS_PER_SOL * amount),
    });

    transaction.add(sendSolInstruction);
    sendTransaction(transaction, connection)
      .then((sig) => {
        setSolAmount("");
        Swal.fire(
          "Transaction Successful!",
          `Your contribution was successful.`,
          "success"
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Transaction Failed",
          text: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!connection || !publicKey) {
      return;
    }

    connection.onAccountChange(
      publicKey,
      (updatedAccountInfo) => {
        setBalance(updatedAccountInfo.lamports / LAMPORTS_PER_SOL);
      },
      "confirmed"
    );

    connection.getAccountInfo(publicKey).then((info) => {
      setBalance(info ? info.lamports / LAMPORTS_PER_SOL : 0);
    });
  }, [connection, publicKey]);

  useEffect(() => {
    const sol = parseFloat(solAmount);
    if (!isNaN(sol)) {
      setTokenAmount((sol * 3.44827586).toFixed(2));
    } else {
      setTokenAmount("");
    }
  }, [solAmount]);

  useEffect(() => {
    if (connection) {
      connection
        .getBalance(new web3.PublicKey(targetAddress))
        .then((balance) => {
          setRaisedAmount((balance / LAMPORTS_PER_SOL) * 170 + 32174.42);
        });
    }
  }, [connection]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(targetAddress)
      .then(() => alert("Address copied to clipboard"))
      .catch((err) => console.error("Failed to copy:", err));
  };

  return (
    <div className="container">
      <div className="row site-font" style={{ justifyContent: "space-around" }}>
        <div
          className="col-md-7"
          style={{ alignItems: "center", display: "flex" }}
        >
          <div className="address-box site_font ">
            <div
              style={{
                marginTop: "40px",
                marginLeft: "0px",
                marginBottom: "40px",
              }}
            >
              {/* <div className="mt-3 d-flex mobile-view">
                <img src={chimpa} className="pej-img2"></img>
              </div>

              <div className="mt-3 mobile-view">
                <img src={pejimg} className="pej-img"></img>
              </div> */}

              <h1 className="site-font bub-heading">
                {" "}
                $ORO – Asset-Backed Token{" "}
              </h1>

              <p
                className="text-dark site-font2 mobile-view"
                style={{ fontSize: "18px" }}
              >
                <strong>$ORO</strong> is a digital token on the Solana
                blockchain, backed by assets of The Order Room trading fund.
                Combining stability with growth potential, $ORO gives investors
                exposure to a diversified portfolio, similar to an ETF, and
                provides passive income through staking rewards generated from
                trading activities.
                <br></br>
                {/* <p className="text-bold">Pre-sale Details: </p> */}
                <p className="mt-2">
                  <strong
                    style={{ fontWeight: "500 !important" }}
                    className="mb-2"
                  >
                    Pre-sale Details:{" "}
                  </strong>{" "}
                  <br /> <strong>8870 $ORO</strong> unlocked and available for
                  pre-sale in 3 phases before listing, contributing over{" "}
                  <strong> $600.000 to the trading fund</strong> <br></br>
                  <li className="mt-2">
                    {" "}
                    <strong> Phase 1: </strong> 985 ORO at 0.29 SOL{" "}
                  </li>
                  <li>
                    {" "}
                    <strong> Phase 2: </strong> 2955 ORO at 0.42 SOL{" "}
                  </li>
                  <li>
                    {" "}
                    <strong> Phase 3: </strong> 4930 ORO at 0.56 SOL{" "}
                  </li>
                  <p className="mt-2">
                    {" "}
                    Before you invest, for more complete information about The
                    Order Room Economy, you should carefully{" "}
                    <a
                      href="https://theorderroom.com/whitepaper"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      read the whitepaper.
                    </a>{" "}
                  </p>
                </p>
              </p>
              <div className="mobile-view">
                {/* <a href="https://www.bubblesjackson.com/" target="_blank">
                  <button className="button-32" style={{ width: "25%" }}>
                    Home
                  </button>
                </a> */}
                <a
                  href="https://t.me/theorderroom"
                  className="mx-2"
                  target="_blank"
                >
                  <button
                    className="button-32 text-center"
                    style={{ width: "26%" }}
                  >
                    Telegram
                  </button>
                </a>
                <a href="https://x.com/theorderroom" target="_blank">
                  <button className="button-32" style={{ width: "15%" }}>
                    X
                  </button>
                </a>
                <a href="https://discord.gg/WQYQWScJaP" target="_blank">
                  <button className="button-32 mx-2" style={{ width: "25%" }}>
                    Discord
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 order-md-1 order-first-mobile">
          <div
            className="responsive-card card-type"
            style={{ marginTop: "50px" }}
          >
            <div
              className="text-center"
              style={{ justifyContent: "center", display: "flex" }}
            >
              {publicKey ? (
                <WalletMultiButton style={{ background: "black" }}>
                  {publicKey.toBase58().substring(0, 3)}...
                  {publicKey
                    .toBase58()
                    .substring(publicKey.toBase58().length - 3)}
                </WalletMultiButton>
              ) : (
                <WalletMultiButton
                  className="site-font2 button-32"
                  style={{
                    background: "black",
                    border: "3px solid black",
                    borderRadius: "10px",
                    color: "white",
                  }}
                >
                  Connect Wallet
                </WalletMultiButton>
              )}
            </div>
            <div
              className="presale-text site_font"
              style={{ fontSize: "13px" }}
            >
              {/* <h2
                className="text-center site-font2"
                style={{
                  marginTop: "10px",
                  fontSize: "1.7rem",
                  color: "black",
                  fontWeight: "700",
                }}
              >
                $ORO Token Launch
              </h2> */}

              {/* <h2
                className="text-center site-font2"
                style={{
                  marginTop: "10px",
                  fontSize: "1.7rem",
                  color: "black",
                  fontWeight: "700",
                }}
              >
                August 29th, @8:00am On Michael Jackson's Birthday
              </h2> */}

              <h2 className="text-center">Presale Ends In</h2>

              <Countdown
                date={parseInt("1735453886") * 1000}
                renderer={renderer}
                className="site-font2"
              />
              <h2
                className="text-center site-font2"
                style={{ marginTop: "10px", color: "black" }}
              >
                BUY $ORO IN PRESALE NOW!
              </h2>
              <h2
                className="text-center site-font2  "
                style={{ marginTop: "20px" }}
              >
                CURRENT RAISED: {raisedAmount.toFixed(2)} USD
              </h2>
              {/* {raisedAmount.toFixed(2)} */}
              <div className="text-center" style={{ marginTop: "20px" }}>
                <h2 className="text-center border-text site-font2">
                  1 ORO = 0.29 SOL
                </h2>
              </div>
            </div>
            <div className="site_font text-black" style={{ marginTop: "10px" }}>
              <div style={{ fontSize: "23px" }} className="text-center">
                <p>
                  {publicKey ? `Your Wallet: ${balance.toFixed(3)} SOL` : ""}
                </p>
              </div>
              <form
                onSubmit={sendSol}
                className={styles.form}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <label
                      htmlFor="amount"
                      style={{ fontSize: "20px" }}
                      className="site-font2"
                    >
                      SOL:
                    </label>
                    <input
                      id="amount"
                      type="text"
                      className={styles.formField}
                      placeholder="1"
                      value={solAmount}
                      onChange={(e) => setSolAmount(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px" }}>
                    <label
                      htmlFor="tokenReceive"
                      style={{ fontSize: "20px" }}
                      className="site-font2"
                    >
                      ORO:
                    </label>
                    <input
                      id="tokenReceive"
                      type="text"
                      className={styles.formField}
                      placeholder="Token"
                      value={tokenAmount}
                      readOnly
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="button-32 site-font2"
                  style={{ width: "90%", alignSelf: "center" }}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "BUY NOW"}
                </button>
              </form>
            </div>
            <div
              className="presale-text site_font2"
              style={{ fontSize: "17px" }}
            >
              <h2>Tokens will be sent to your wallet after presale ends.</h2>
            </div>
          </div>
        </div>
      </div>
      <p
          className="mobile-font mt-2"
          style={{ color: "#b22222", fontSize: "20px", marginBottom: "40px" }}
        >
          Important: If you are using the Phantom wallet, you may see a standard warning message. This is common for newer wallets. Simply click "Continue" to proceed securely with your purchase.
        </p>

            </div>
  );
}
